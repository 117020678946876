import React from 'react'
import { Box, Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Timer as TimerIcon } from '@material-ui/icons'
import { useTranslation } from 'react-i18next'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

import { SEO } from 'components'
import { useSizes } from 'hooks'

function Recipes() {
  const { t } = useTranslation()
  const classes = useStyles()
  const { mdUp } = useSizes()
  const { cookie, milkshakeOreo } = useStaticQuery(graphql`
    query {
      cookie: file(relativePath: { eq: "cookie.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      milkshakeOreo: file(relativePath: { eq: "milkshakeOreo.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  `)

  const recipes = [
    {
      id: 1,
      img: cookie.childImageSharp.fluid,
      name: 'chocolateCookies',
      duration: 50,
      description:
        'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry standard dummy text ever since the 1500s'
    },
    {
      id: 2,
      img: milkshakeOreo.childImageSharp.fluid,
      name: 'oreoMilkshake',
      duration: 25,
      description:
        'Lorem Ipsum is simply dummy text of the printing and typesetting industry. '
    },
    {
      id: 3,
      img: cookie.childImageSharp.fluid,
      name: 'chocolateCookies',
      duration: 50,
      description:
        'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry standard dummy text ever since the 1500s'
    },
    {
      id: 4,
      img: milkshakeOreo.childImageSharp.fluid,
      name: 'oreoMilkshake',
      duration: 25,
      description:
        'Lorem Ipsum is simply dummy text of the printing and typesetting industry. '
    }
  ]

  return (
    <>
      <SEO title={t('recipes')} />
      <Box pt={5}>
        <Grid container alignItems='stretch'>
          {recipes.map(recipe => {
            return (
              <Grid item xs={12} md={6} key={recipe.id}>
                <Box
                  display='flex'
                  alignItems='center'
                  justifyContent='center'
                  py={5}
                  px={mdUp ? 15 : 2}
                  flexDirection='column'
                >
                  <Box className={classes.recipeImgContainer}>
                    <Img fluid={recipe.img} alt='recipe image' />
                  </Box>
                  <Typography
                    className={classes.recipeName}
                    variant='h6'
                    color='primary'
                    align='center'
                  >
                    {t(recipe.name).toUpperCase()}
                  </Typography>

                  <Box
                    display='flex'
                    alignItems='center'
                    justifyContent='center'
                    py={1}
                  >
                    <TimerIcon className={classes.timerIcon} />
                    <Typography variant='h6' color='primary' align='center'>
                      {`${t(recipe.duration)} ${t('minutes').toUpperCase()}`}
                    </Typography>
                  </Box>
                  <Typography variant='h6' color='primary' align='center'>
                    {t(recipe.description)}
                  </Typography>
                </Box>
              </Grid>
            )
          })}
        </Grid>
      </Box>
    </>
  )
}

const useStyles = makeStyles(theme => ({
  recipeImgContainer: {
    width: 300,
    marginBottom: theme.spacing(3)
  },
  recipeName: {
    fontWeight: 'bold'
  },
  timerIcon: {
    marginRight: theme.spacing(1)
  }
}))

export default Recipes
